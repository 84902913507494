@tailwind base;
@tailwind components;
@tailwind utilities;

root {
    background-color: white;
}

.spinner {
    height: 1em;
    width: 1em;
    border: 1px solid #ff7360;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: #ffd2cb;
    animation: rotate 1.2s linear infinite;
    box-sizing: border-box;
}

@keyframes rotate {
    100% {
        transform: rotate(-360deg);
    }
}

.font-outfit, .Toastify__toast {
    font-family: 'Outfit', sans-serif !important;
}
